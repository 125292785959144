import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        noPic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2016-10-07",
  "title": "Deciding What Not To Learn",
  "description": "You don't have to learn everything.",
  "cover": "/img/cover/what-not-to-learn-cover.jpg",
  "noPic": true,
  "color": "#bebcbf"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Whether you're new to web development, recently coming back to it, or have been doing it for years, you've probably asked yourself this question more than once:`}</p>
    <blockquote>
      <p parentName="blockquote">{`How can I keep up with all this stuff?`}</p>
    </blockquote>
    <p>{`Well I have good news: you can't. And even if you could, it would be a huge waste of your time.`}</p>
    <p>{`The number of new things to learn grows at a faster rate than your ability to learn them. By the time you ship your project, half your stack is considered `}<strong parentName="p">{`deprecated`}</strong>{` by the cool kids. Some folks call this churn, others call it innovation. It can be exciting. It can be exhausting.`}</p>
    <p>{`I used to keep a long list of all the tech I would someday get around to learning. The problem with that is it just made me feel even more anxious. And if I was being completely honest with myself, I didn't actually have a ton of interest in most of them. I just didn't want to get left behind.`}</p>
    <p>{`So instead I'm trying something different. I'm making a list of things that I'm `}<strong parentName="p">{`not`}</strong>{` going to learn. First I organize all the tech into categories that make sense in my mind:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/tech1.JPG",
        "alt": "tech organized into categories"
      }}></img></p>
    <p>{`Then I simply pick the ones that I'm truly interested in right now, and cross out everything else. Things I `}<em parentName="p">{`already`}</em>{` know but don't plan on investing any more learning/attention to get crossed out. Things I know well enough but that keep on giving the more you put into mastering them (like git) make the cut.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/tech2.JPG",
        "alt": "tech cut"
      }}></img></p>
    <p>{`An interesting thing happens when you do this. You don't feel like you have to learn `}<em parentName="p">{`everything`}</em>{`. You don't have to stay up to date on something you learned a couple years ago (you can always look things up if you need). That precious headspace and mental energy get freed up to learn the few things you're actually excited about `}<em parentName="p">{`right now`}</em>{`.`}</p>
    <p>{`Note: these are `}<em parentName="p">{`my`}</em>{` interests, not a prescription for everyone. You should do this for your own interests. You may also want to include backend/DB tech.`}</p>
    <p>{`It helps to think of the things you're crossing out as `}<strong parentName="p">{`R&D`}</strong>{` (Research & Development) for the node they branch out from. For example I'm not going to be learning Ember any time soon, but Ember is doing things that is making `}<em parentName="p">{`all`}</em>{` the other current and future frameworks better. Same for Elm, Reason, and TypeScript. I'm not personally going to spend my time on them, but I'm grateful for those who are because I know that many of their awesome ideas will influence JavaScript.`}</p>
    <p>{`For me the litmus test is simple: `}<strong parentName="p">{`true interest`}</strong>{`. Not shallow interest because of a talk I heard at some conference. Not feigned interest because that's what hot and I'm supposed to be a hot shot. True interest or it's out.`}</p>
    <p>{`So, what do you really want to learn? What do you `}<strong parentName="p">{`not`}</strong>{` want to learn? Grab a piece of paper and keep it real.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      